<template>
  <v-container>
    <v-row>
      <v-col col="12" sm="12" md="12">
        <h2>Work Experience</h2>
        <v-divider></v-divider>
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-btn color="primary" class="mr-4 text--white" @click="openDialog()"
          >Add Info...</v-btn
        >
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-data-table :headers="headers" :items="desserts" class="elevation-1">
          <template v-slot:item="row">
            <tr>
              <td nowrap>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="updates(row.item)"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="red"
                  @click="deletes(row.item)"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </td>
              <td>{{ row.item.DFrom }}</td>
              <td>{{ row.item.DTo }}</td>
              <td>{{ row.item.Position }}</td>
              <td>{{ row.item.Agency }}</td>
              <td>{{ row.item.Salary }}</td>
              <td>{{ row.item.SG }}</td>
              <td>{{ row.item.Status }}</td>
              <td v-if="row.item.Govt == 'YES' || row.item.Govt == 'Y'">YES</td>
              <td v-else>NO</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Information</span>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="DFrom"
                      label="From"
                      type="date"
                      :rules="[
                        vv.init(this.DFrom),
                        vv.requiredDate(),
                      ]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="DTo"
                      label="To"
                      type="date"
                      :rules="[
                        vv.init(this.DTo),
                        vv.requiredDate(),
                      ]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="Position"
                      label="Position"
                      :rules="[
                        vv.init(this.Position),
                        vv.required(),
                        vv.maxlen(255),
                      ]"
                      auto-grow
                      outlined
                      dense
                      rows="1"
                      row-height="15"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">

                     <v-textarea
                      v-model="Agency"
                      label="Agency"
                      :rules="[
                        vv.init(this.Agency),
                        vv.required(),
                        vv.maxlen(255),
                      ]"
                      auto-grow
                      outlined
                      dense
                      rows="1"
                      row-height="15"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="Salary"
                      label="Salary"
                      type="number"
                      :rules="[
                        vv.init(this.Salary),
                        vv.requiredNumber(),
                        vv.digit_range(0,5000000),
                      ]"
                      required
                      outlined
                      dense
                      prefix="₱"
                    ></v-text-field>


                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="SG"
                      label="Salary Grade"
                      :rules="[vv.init(this.SG), vv.required(), vv.maxlen(255)]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="Status"
                      label="Status"
                      :rules="[
                        vv.init(this.Status),
                        vv.required(),
                        vv.maxlen(255),
                      ]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    

                    <v-autocomplete
                      v-model="Govt"
                      :items="govtOpt"
                      :rules="[vv.init(this.Govt), vv.requiredSelect()]"
                      item-text="name"
                      label="Govt"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Close</v-btn
            >
            <v-btn
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="savechanges()"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import vldn from "@/js/validation";
export default {
  name: "We",
  data: () => ({
    valid: false,
    formTitle: "",
    dialog: false,
    headers: [
      {
        text: "Action",
        align: "start",
        sortable: false,
        value: "action",
      },
      { text: "From", sortable: false, value: "DFrom" },
      { text: "To", sortable: false, value: "DTo" },
      { text: "Position Title", sortable: false, value: "Position" },
      {
        text: "Department/Agency/Office/Company",
        sortable: false,
        value: "Agency",
      },
      { text: "Monthly Salary", sortable: false, value: "Salary" },
      { text: "Salary Grade", sortable: false, value: "SG" },
      { text: "Status of appointment", sortable: false, value: "Status" },
      { text: "Govt Service", sortable: false, value: "Govt" },
    ],
    desserts: [],
    ID: 0,
    DFrom: "",
    DTo: "",
    Position: "",
    Agency: "",
    Salary: "",
    SG: "",
    Status: "",
    Govt: "",
    vv: vldn.methods,
    govtOpt: [
      { name: "YES", value: "Y" },{ name: "NO", value: "N" }
    ]
  }),
  componets: {
    vldn,
  },
  mounted() {
    this.$api.methods.init();
    this.Govt = "Y"
    this.getwe();
  },
  methods: {
    getwe() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "pds/we",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            this.desserts = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    openDialog() {
      this.ID = 0;
      this.dialog = true;
    },
    updates(item) {
      this.ID = item.ID;
      this.DFrom = item.DFrom;
      this.DTo = item.DTo;
      this.Position = item.Position;
      this.Agency = item.Agency;
      this.Salary = item.Salary;
      this.SG = item.SG;
      this.Status = item.Status;
      
      if(item.Govt == "YES" || item.Govt == "Y"){
        this.Govt = "Y";
      }else if(item.Govt == "NO" || item.Govt == "N"){
        this.Govt = "N";
      }else{
        this.Govt = "N";
      }
      this.dialog = true;
    },
    deletes(item) {
      this.$axios({
        method: "post",
        url: process.env.VUE_APP_BASE_URL + "pds/deletewe",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
        data: {
          ID: item.ID,
        },
      })
        .then((response) => {
          if (response) {
            this.getwe();
            this.ID = 0;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    savechanges() {

      if (this.$refs.form.validate()) {
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_BASE_URL + "pds/updatewe",
          headers: {
            Authorization: "Bearer" + localStorage.getItem("usertoken"),
          },
          data: {
            ID: this.ID,
            DFrom: this.DFrom,
            DTo: this.DTo,
            Position: this.Position,
            Agency: this.Agency,
            Salary: this.Salary,
            SG: this.SG,
            Status: this.Status,
            Govt: this.Govt,
          },
        })
          .then((response) => {
            if (response) {
              this.getwe();
              this.ID = 0;
              this.DFrom = "";
              this.DTo = "";
              this.Position = "";
              this.Agency = "";
              this.Salary = "";
              this.SG = "";
              this.Status = "";
              this.Govt = "Y";
              this.dialog = false;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 500) {
                //
              }
            }
          });
      }
    },
  },
};
</script>
<style>
.container {
  max-width: 80%;
}
</style>
